import styled from 'styled-components'
import { Media, fillBtnStyle, lineBtnStyle } from '@raylink/common/theme'
import { Warpper } from '@raylink/common/components'
import PrivateBg from '../../../images/price-business/private-bg.png'
import PrivateIpadBg from '../../../images/price-business/private-ipad-bg.png'
import PrivatePhoneBg from '../../../images/price-business/private-phone-bg.png'

export const Title = styled.h1`
  display: flex;
  position: relative;
  gap: 20px;
  margin: 0;
  padding: 128px 0 40px;
  > div {
    font-size: 48px;
    font-weight: 700;
    color: #212121;
    text-align: center;
    line-height: 1.5;
  }
  .last-text {
    position: relative;
  }
  .highlight {
    color: #455fff;
    font-weight: 700;
  }
  img {
    position: absolute;
    width: 294px;
    top: 58px;
    left: -2px;
  }
  .dot {
    &::after {
      content: '·';
      font-size: 48px;
      margin: 0 6px;
    }
  }
  ${Media.ipad`
    padding: 110px 0 30px;
    gap: 16px;
    > div {
      font-size: 40px;
    }
    img {
      width: 241px;
      top: 48px;
      left: 0;
    }
    .dot {
      &::after {
        content: '·';
        font-size: 40px;
        margin: 0 4px;
      }
    }
  `}
  ${Media.phone`
    flex-direction: column;
    padding: 1.5rem 0 0.56rem;
    gap: 0;
    > div {
      font-size: 0.68rem;
      line-height: 0.92rem;
    }
    img {
      position: absolute;
      width: 4rem;
      top: 0.8rem;
      left: 0;
    }
    .dot {
      &::after {
        content: '·';
        font-size: 0.68rem;
        margin: 0 0.1rem;
      }
    }
  `}
`

export const Banner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 75.19%, #fff 100%),
    linear-gradient(
      90deg,
      rgba(213, 222, 255, 0.5) 0%,
      #f1f9fd 49.48%,
      rgba(216, 245, 234, 0.5) 100%
    );
`

export const PriceListWrap = styled(Warpper)`
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
  ${Media.ipad`
    margin-top: 32px;
  `}
  ${Media.phone`
    flex-direction: column;
    align-items: center;
    gap: 0.32rem;
    margin-top: 0.76rem;
  `}
`

export const PriceCard = styled.div`
  width: 378px;
  height: 574px;
  padding: 32px 36px;
  border-radius: 16px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.7) 100%
  );

  ${({ isPrivate }) =>
    isPrivate
      ? `background: url(${PrivateBg}) no-repeat 100% center / 100%;`
      : ''}
  ${Media.ipad`
    width: 298px;
    height: 542px;
    padding: 32px 24px;
    ${({ isPrivate }) =>
      isPrivate
        ? `background: url(${PrivateIpadBg}) no-repeat 100% center / 100%;`
        : ''}
  `}
  ${Media.phone`
    width: 6.46rem;
    height: auto;
    padding: 0.64rem;
    border-radius: 0.32rem;
    ${({ isPrivate }) =>
      isPrivate
        ? `background: url(${PrivatePhoneBg}) no-repeat 100% center / 100%;`
        : ''}

  `}
`

export const PriceTop = styled.div`
  height: 181px;
  .title {
    font-size: 24px;
    line-height: 1.5;
    font-weight: bold;
    background: linear-gradient(90deg, #2d45db 0%, #455fff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
  }
  pre {
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .text {
    margin: 6px 0 0;
    font-size: 14px;
    color: #646a73;
  }
  .price {
    font-family: 'D-DIN-PRO';
    font-weight: 500;
    margin-top: 53px;
    font-size: 48px;
    color: #212121;
    font-weight: bold;
    line-height: 1;
    &::before {
      content: '¥';
      font-weight: bold;
      font-size: 24px;
      margin-right: 4px;
    }
    &::after {
      content: '/用户/年';
      font-family: 'Source Han Sans CN';
      color: #646a73;
      font-weight: 500;
      font-size: 15px;
      margin-left: 4px;
    }
  }
  .private-qr {
    width: 72px;
    margin-top: 24px;
  }
  ${Media.ipad`
    height: 163px;
    .text {
      margin: 4px 0 0;
    }
    .price {
      margin-top: 24px;
    }
    .private-qr {
      margin-top: 15px;
    }
  `}
  ${Media.phone`
    height: 3.26rem;
    .title {
      font-size: 0.48rem;
    }
    .text {
      margin: 0.08rem 0 0;
      font-size: 0.28rem;
    }
    .price {
      margin-top: 0.48rem;
      font-size: 0.96rem;
      &::before {
        font-size: 0.48rem;
        margin-right: 0.08rem;
      }
      &::after {
        font-size: 0.3rem;
        margin-left: 0.18rem;
      }
    }
    .private-qr {
      width: 1.44rem;
      margin-top: 0.3rem;
    }
  `}
`
export const PriceDesc = styled.div``

export const PriceBtn = styled.a`
  ${({ isPrivate }) => (isPrivate ? lineBtnStyle : fillBtnStyle)}
  height: 44px;
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: 500;
  background: #455fff;
  &:hover {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.1) 100%
      ),
      #455fff;
  }
  &:active {
    background: linear-gradient(0deg, #455fff, #455fff),
      linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
  }
  ${({ isPrivate }) =>
    isPrivate
      ? `
        ${lineBtnStyle}
        box-sizing: border-box;
        border: 1px solid #455fff;
        border-radius: 6px;
        color: #455fff;
        background: #fff;
        &:hover,
        &:active {
          color: #fff;
          background: #455fff;
          border: 1px solid #455fff;
        };
      `
      : ''}
  ${Media.ipad`
    margin-bottom: 24px;
  `}
  ${Media.phone`
    height: 0.88rem;
    margin-bottom: 0.48rem;
    font-size: 0.32rem;
    ${({ isPrivate }) =>
      isPrivate
        ? `
          border-radius: 0.12rem;
        `
        : ''}
  `}
`

export const FeatsListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .feat-item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    line-height: 1.5;
  }
  ${Media.phone`
    gap: 0.32rem;
    .feat-item {
      gap: 0.2rem;
      font-size: 0.28rem;
    }
  `}
`

export const FullContainerBg = styled.div`
  padding: 80px 0 0;
  .full-img {
    width: 72px;
  }
  ${Media.ipad`
    padding: 56px 0 0;
  `}
  ${Media.phone`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.8rem 0 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 91.09%, #ffffff 100%);
    .full-img {
      width: 1.28rem;
    }
  `}
`

export const CompareWrap = styled.div`
  padding-top: 80px;
  .title {
    color: #1f2329;
    font-weight: 700;
    font-size: 32px;
    margin: 0 0 32px;
    text-align: center;
  }
  .see-more {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin-top: 24px;
    font-size: 14px;
    color: #455fff;
    cursor: pointer;
    img {
      width: 16px;
    }
  }
  ${Media.ipad`
    padding-top: 56px;
    .title {
      margin: 0 0 20px;
    }
  `}
  ${Media.phone`
    padding-top: 0.8rem;
    .title {
      font-size: 0.56rem;
      margin: 0;
    }
    .see-more {
      gap: 0.08rem;
      margin-top: 0.48rem;
      font-size: 0.28rem;
      img {
        width: 0.32rem;
      }
    }
  `}
`

export const TableTitle = styled.div`
  padding: 24px 0;
  text-align: center;
  .t-title {
    font-size: 20px;
    font-weight: 700;
  }
  .t-desc {
    font-family: 'D-DIN-PRO';
    font-size: 16px;
    margin: 2px auto 8px;
    font-weight: 700;
    span {
      font-size: 18px;
    }
    &::before {
      content: '¥';
      font-size: 16px;
    }
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: auto;
    padding: 0 12px;
    height: 30px;
    font-size: 14px;
    border-radius: 3px;
    font-weight: 500;
    color: #fff;
    background: #455fff;
    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.1) 0%,
          rgba(255, 255, 255, 0.1) 100%
        ),
        #455fff;
    }
  }
  ${Media.phone`
    padding: 0;
    .t-title {
      font-size: 0.36rem;
    }
    .t-desc {
      font-size: 0.32rem;
      margin: 0.04rem auto 0.16rem;
      span {
        font-size: 0.36rem;
      }
      &::before {
        font-size: 0.32rem;
      }
    }
    .btn {
      padding: 0 0.24rem;
      height: 0.6rem;
      font-size: 0.28rem;
      border-radius: 0.06rem;
    }
  `}
`

export const BusinessQuestion = styled.div`
  div .question-item-label__unfold {
    color: #455fff;
  }
`
