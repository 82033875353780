import React, { useEffect, useRef, useState } from 'react'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import { Layout, SEO } from '@raylink/common/components'
import { antdThemeGlobal } from '@raylink/common/theme'
import {
  Title,
  Banner,
  PriceListWrap,
  PriceCard,
  PriceTop,
  PriceDesc,
  PriceBtn,
  FeatsListWrap,
  FullContainerBg,
  CompareWrap,
  TableTitle,
  BusinessQuestion,
} from './index.style'
import { QuestionWrap } from '../index.style'
import MoreImg from '../../../images/price-business/more-bottom.png'
import CheckImg from '../../../images/price-business/check-icon.svg'
import TableCheckImg from '../../../images/price-business/table-check.svg'
import DownArrowImg from '../../../images/price-business/down-arrow.svg'
import {
  BusinessType,
  businessPriceList,
  fullItemList,
  KeyEnum,
  compareList,
  questionList,
} from './config'
import CompareTable from '../components/compareTable'
import FullContainer from '../components/full-container'
import PriceTabs from '../components/price-tabs'
import { normalizeTableList } from '../utils/normalize-table-list'
import { queryBusinessPrice } from '../utils/query-business-price'
import { Path } from '@raylink/common/constants/path'
import { useMedia } from '@raylink/common/hooks'

export default function PriceBusiness() {
  const isMobile = useMedia()
  const [priceData, setPriceData] = useState(businessPriceList)
  const [isCut, setIsCut] = useState(true) // 是否收起数据
  const quesUnfoldRefs = useRef([])

  const cutCompareList = [...compareList].map(item => {
    if (item.type === '远控功能') {
      return {
        ...item,
        features: item?.features?.slice(0, 10),
      }
    }
    return item
  })

  // 表格数据  ‘远控功能’模块需要收起第10条之后的数据
  const [tableData, setTableData] = useState(cutCompareList)

  const officeTitle = ({ title, desc, num, href }) => (
    <TableTitle>
      <div className="t-title">{title}</div>
      <div className="t-desc">
        <span>{num}</span>
        {desc}
      </div>
      <a href={href} className="btn">
        立即购买
      </a>
    </TableTitle>
  )

  const titleList = [
    {
      label: officeTitle({
        title: '办公专业版',
        desc: ' / 用户 / 年',
        num: priceData[0].priceNum,
        href: Path.buyOfficeVip,
      }),
      key: KeyEnum.deploy,
    },
    {
      label: officeTitle({
        title: '行业性能版',
        desc: ' / 用户 / 年',
        num: priceData[1].priceNum,
        href: Path.buyIndustryVip,
      }),
      key: KeyEnum.industry,
    },
  ]

  useEffect(() => {
    const setPrice = (dataList, priceKey, priceNum) => {
      const a = dataList.find(({ key }) => key === priceKey)
      a.priceNum = priceNum
    }
    queryBusinessPrice().then(({ officePrice, industryPrice }) => {
      if (officePrice) {
        setPrice(priceData, BusinessType.office, officePrice)
      }
      if (industryPrice) {
        setPrice(priceData, BusinessType.industry, industryPrice)
      }
      setPriceData([...priceData])
    })
  }, [])

  useEffect(() => {
    setTableData(isCut ? cutCompareList : compareList)
  }, [isCut])

  return (
    <ConfigProvider theme={antdThemeGlobal} locale={zhCN}>
      <Layout>
        <SEO
          title="RayLink企业版价格-远程连接软件价格-远控软件价格"
          keywords="企业远程控制,远程控制软件,远控软件,远程软件,远程连接软件"
          description="RayLink远程控制软件是一款服务于企业及个人的真高清、超流畅远程控制软件，跨端支持设备远程连接，满足远程办公、远程协助、远程设计，10ms无感低延时，媲美本地操作"
          ogUrl="price-for-business.html"
        />

        <Banner>
          <Title>
            <div>
              <span className="dot">安全</span>
              <span className="dot">高效</span>
              <span>专业</span>
            </div>
            <div className="last-text">
              <span className="highlight">企业远控优选</span>
              <span>解决方案</span>
              <img src={MoreImg} alt="" loading="lazy" />
            </div>
          </Title>

          <PriceTabs tab="business" />

          <PriceListWrap>
            {businessPriceList.map(item => (
              <PriceCard
                key={item.key}
                isPrivate={item.key === BusinessType.private}
              >
                <PriceTop>
                  <div className="title">{item.title}</div>
                  <PriceDesc as={isMobile ? 'pre' : 'div'} className="text">
                    {item.text}
                  </PriceDesc>
                  {item.key === BusinessType.private ? (
                    <img
                      src={item.qRSrc}
                      alt=""
                      className="private-qr"
                      loading="lazy"
                    />
                  ) : (
                    <div className="price">{item.priceNum}</div>
                  )}
                </PriceTop>
                <PriceBtn
                  isPrivate={item.key === BusinessType.private}
                  href={item.href}
                >
                  {item.btnText}
                </PriceBtn>

                <FeatsListWrap>
                  {item.feats.map(f => (
                    <div className="feat-item" key={f}>
                      <img src={CheckImg} alt="" loading="lazy" />
                      {f}
                    </div>
                  ))}
                </FeatsListWrap>
              </PriceCard>
            ))}
          </PriceListWrap>

          <FullContainerBg>
            <FullContainer list={fullItemList} />
          </FullContainerBg>
        </Banner>

        <CompareWrap id="compareTable">
          <h2 className="title">功能权益对比</h2>
          <CompareTable
            titleList={titleList}
            compareList={normalizeTableList(tableData, titleList)}
            checkImg={TableCheckImg}
          />
          {isCut && (
            <div className="see-more" onClick={() => setIsCut(false)}>
              查看更多
              <img src={DownArrowImg} alt="" loading="lazy" />
            </div>
          )}
        </CompareWrap>

        <BusinessQuestion>
          <QuestionWrap>
            <h2>常见问题</h2>
            {questionList.map(({ question, answer }, idx) => (
              <div key={question} className="question-item">
                <div
                  ref={el => {
                    quesUnfoldRefs.current[idx] = el
                  }}
                  className="question-item-label"
                  onClick={() => {
                    quesUnfoldRefs.current[idx].classList.toggle(
                      'question-item-label__unfold'
                    )
                  }}
                >
                  <div className="question-item-question">{question}</div>
                </div>
                <div className="question-item-content">
                  {Array.isArray(answer) ? (
                    answer.map((a, i) => (
                      <p key={a} className="question-item-answer">
                        {i + 1}. {a}
                      </p>
                    ))
                  ) : (
                    <p className="question-item-answer">{answer}</p>
                  )}
                </div>
              </div>
            ))}
          </QuestionWrap>
        </BusinessQuestion>
      </Layout>
    </ConfigProvider>
  )
}
